<template>
  <v-card
    min-height="550"
    width="420"
    elevation="10"
    dark
    class="d-flex justify-center align-content-center flex-column glass-card"
  >
    <v-card-text class="px-11" @keypress.enter="submit">
      <h6 class="text-start text-subtitle-1 pl-3 mb-4 white--text">
        {{ $_t("authentication.components.auth.welcome") }}
      </h6>
      <h1 class="text-start pl-3 mb-15 pb-5 white--text">
        {{ $_t("authentication.components.auth.title") }}
      </h1>
      <GreenSoftForm>
        <!--Start form body    ------------------------------------------------------------------>
        <template v-slot:body>
          <!--Start form wrapper for catch errors  ------------------------>
          <FormGenerator
            lg="6"
            @clickAppend="showPassword"
            :schema="schema"
            :validator="$v"
            :enter="submit"
          ></FormGenerator>
          <!--End   form wrapper for catch errors  ------------------------>
        </template>
        <!--End   form body    ------------------------------------------------------------------>

        <!--Start form actions ------------------------------------------------------------------>
        <template v-slot:action>
          <v-col cols="12">
            <v-btn
              class="mt-10"
              color="primary"
              height="50px"
              block
              @click="submit"
              :loading="is_loading"
              :disabled="is_loading || $v.$error"
            >
              {{ $_t("authentication.components.auth.buttons.login") }}
            </v-btn>
          </v-col>
          <v-col cols="12">
            <router-link
              class="white--text text-decoration-none"
              :to="{ name: 'contact' }"
              >{{ $_t("authentication.router_title.contact_us") }}</router-link
            >
          </v-col>
        </template>
        <!--End   form actions ------------------------------------------------------------------>
      </GreenSoftForm>
    </v-card-text>
  </v-card>
</template>

<script>
import { FORM_LOGIN_SCHEMA } from "@/packages/authenticate/schema/forms/LOGIN_SCHEMA";
import { mapGetters } from "vuex";
import GreenSoftForm from "@/components/app/form/MainSoftForm";
import FormGenerator from "@/components/app/form/MyFormGenerator";
import message from "@/tools/Message";

export default {
  name: "Auth",
  components: { FormGenerator, GreenSoftForm },
  computed: {
    ...mapGetters({
      is_loading: "loading/is_loading",
    }),
  },
  data: () => ({
    schema: FORM_LOGIN_SCHEMA.schema,
    form: FORM_LOGIN_SCHEMA.model,
  }),
  validations: {
    ...FORM_LOGIN_SCHEMA.validations,
  },
  methods: {
    async submit() {
      if (!this.isFormValidate()) return;
      try {
        const auth_data = await this.$actions.login({
          userName: this.form.userName,
          password: this.form.password,
          clientId: process.env.VUE_APP_CLIENT_ID,
          clientSecret: process.env.VUE_APP_CLIENT_SECRET,
          captchaCode: process.env.VUE_APP_CAPTCHA_CODE,
        });
        if (auth_data) {
          message.success("LOGIN_SUCCESS");
          if (auth_data.role === "Student")
            return await this.$router.push({ name: "report.main" });
          else return await this.$router.push({ name: "admin.users" });
        }
      } catch (e) {
        console.log(e, "ERR");
      }
    },
    showPassword() {
      this.schema[1].showPassword = !this.schema[1].showPassword;
    },
  },
  mounted() {
    document.getElementById("userName").focus();
  },
};
</script>
