<template>
  <Auth />
</template>

<script>
import Auth from "@/packages/authenticate/components/Auth";

export default {
  components: {
    Auth,
  },
};
</script>
